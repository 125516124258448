<template>
  <div class="LeftNavWrap" >
    
    <div style="font-size:24px; color:rgb(66, 167, 169); float: right;margin-top: 18px; width:15px;" v-if="navList.length == 0 ? false : true" class="toggle-button" @click="toggleCollapse">
      <!-- <span v-if="!isCollapse" class="glyphicon glyphicon-align-left"></span>
      <span v-else class="glyphicon glyphicon-align-right"></span> -->
      <el-icon style="" v-if="!isCollapse"><Fold style='color:grey;' /></el-icon>
      <el-icon v-else><Expand style='color:grey;' /></el-icon>
    </div>
    <el-aside :width="isCollapse ? '64px':'200px'">
    <div class="LeftNav">
      <div class="home_left" v-if="homeShowInfo">
        <div class="userInfo">
          <img v-if="userName == '卓安电子' || userName == '夏都物业' || userName == '诚成电子'" :src="getLogoSrc()" style="width:160px;border-radius: 0;" >
          <img v-else :src="getLogoSrc()" style="width: 100px; height: 100px">
          <p>{{ userName }}</p>
          <p>{{ groups }}</p>
          <div class="logintime">
            上次登录：{{
              `${prevhour.year}/${prevhour.month}/${prevhour.date} ${prevhour.hour}:${prevhour.minute}`
            }}
          </div>
          <div class="logintime">
            当前登录：{{ `${year}/${month}/${date} ${hour}:${minute}` }}
          </div>
          <!-- <div class="userInfo_Line"></div> -->
        </div>
        <div class="r_title">
          <img class="tup" src="../assets/img/left6.png" alt="">
          <router-link to="/user_manage/search_man">用户查询</router-link>
        </div>
        <div class="r_title">
          <img class="tup"  src="../assets/img/left1.png" alt="">
          <router-link to="/report_manage/expend_collect">费用查询</router-link>
        </div>
        <div class="r_title">
          <img class="tup" src="../assets/img/left5.png" alt="">
          <router-link to="/revenue_manage/revenue_manage">营收查询</router-link>
        </div>
        <div class="r_title">
          <img class="tup" src="../assets/img/left2.png" alt="">
          <router-link to="/fault_manage/devops_count">故障统计</router-link>
        </div>
        <div class="r_title">
          <img class="tup" src="../assets/img/left4.png" alt="">
          <router-link to="/device_manage/station_add">新增充电站</router-link>
        </div>

        
        <!-- <div class="r_title">
          <span class="glyphicon glyphicon-bell"></span>
          <router-link to="/fee_template/temp_manage">费用管理</router-link>
        </div>
        
        <div class="r_title">
          <span class="glyphicon glyphicon-globe"></span>
          <router-link to="/gis/station">GIS管理</router-link>
        </div>
        <div class="r_title">
          <span class="glyphicon glyphicon-remove-sign"></span>
          <router-link to="/setting/user">系统配置</router-link>
        </div> -->
      </div> 
      <div class="home" v-if="navList.length == 0 ? false : true && !isCollapse">
        <router-link to="/sys_index/home">首页</router-link>
      </div>
      
      
      <el-menu
        style="padding-right:20px"
        v-if="navList.length == 0 ? false : true && !isCollapse"
        background-color="NONE"
        text-color="white"
        active-text-color="white"
        :default-active="activeNav"
        class="left_nav_content"
        router
      >
        <template  v-for="(items, i) in navList">
          <el-sub-menu v-if="items.show" :key="i" :index="items.title">
            <template #title>
              <!-- <div class="navLines"></div> -->
              <span>{{ items.title }}</span>
            </template>
            <template v-for="z in items.items">
              <el-menu-item
                @click="seleMenuItem(z.path, z.text)"
                v-if="z.show"
                :key="z.index"
                :index="z.path"
                >{{ z.text }}
              </el-menu-item>
            </template>
          </el-sub-menu>
        </template>
      </el-menu>
      <div class="toback" v-if="navList.length == 0 ? false : true" @click="toback">
        <!-- <span>返回</span> -->
        <img style="width:50px; height:50px;" src="@/assets/img/back2.png" alt="">
      </div>
    </div>
  </el-aside>
    <div class="tablesWrap" :style="{left:isCollapse ? '114px':'250px'}">
      <el-tabs
        
        v-model="TabActive"
        closable
        @tab-remove="removeTab"
        @tab-click="seleTabItems"
      >
        <el-tab-pane
          v-for="item in TabData"
          :key="item.name"
          :label="item.title"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>
      <el-dropdown v-show="contextMenuVisible">
        <div class="bg_div">
          <span class="glyphicon glyphicon-menu-down"> </span>
          </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="closeOther">关闭其他 </el-dropdown-item>
            <el-dropdown-item @click="closeAll">关闭所有</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue-demi";
import { onMounted, onBeforeUnmount, watch, watchEffect } from "vue";
// useRoute
import { useRouter, useRoute } from "vue-router";

import { useStore } from "vuex";
export default {
  name: "LeftNav",
  data(){
    return {
      isCollapse:false,
    }
  },
  methods:{
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    }
  },
  props: {
    menuList: {
      type: Array,
    },
    modu: {
      type: String,
    },
    homeShowInfo: {
      type: Boolean,
    },
  },
  setup(props,context) {
    
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const data = reactive({
      activeNav:
        props.menuList.length > 0 ? props.menuList[0].items[0].path : [],
      // tab标签页数据存放容器
      TabActive:
        props.menuList.length > 0 ? props.menuList[0].items[0].path : "home",
      TabData: [
        {
          title:
            props.menuList.length > 0
              ? props.menuList[0].items[0].text
              : "首页",
          name:
            props.menuList.length > 0
              ? props.menuList[0].items[0].path
              : "home",
        },
      ],
      navList: [],
      menuIsOpen: true,
      homeShowInfo: props.homeShowInfo,
      screenWidth:0,
      // 当前时间
      year: "",
      month: "",
      date: "",
      hour: "",
      minute: "",
      week: "",
      // 一小时前的时间
      prevhour: {
        year: "",
        month: "",
        date: "",
        hour: "",
        minute: "",
        week: "",
      },
      // 当前天气
      weather: {
        city: "",
        temperature: "",
      },
      // 快捷键数据
      TODOList: [],
      message: [],
      contextMenuVisible:false,
    });
    const userName = ref(sessionStorage.getItem("name"));
    const getLogoSrc = () => {
      switch (userName.value) {
        case 'admin':
          return require('../assets/mjimg/avatar.jpg')
        case '卓安电子':
          return require('../assets/mjimg/logo-zadz.png')
        case '夏都物业':
          return require('../assets/mjimg/logo-xdwy.png')
        case '诚成电子':
          return require('../assets/mjimg/logo-ccdz.png')
        default:
          return require('../assets/mjimg/logo-default.png')
      }
    };
    // 获取时间
    const getDate = () => {
      const now = new Date();
      data.year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      data.month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      data.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      data.hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      data.minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      const day = now.getDay();
      const weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      data.week = weeks[day];

      const prevHourtime = new Date(now.getTime() - 3600000);
      data.prevhour.year =
        prevHourtime.getFullYear() < 10
          ? "0" + prevHourtime.getFullYear()
          : prevHourtime.getFullYear(); //得到年份
      data.prevhour.month =
        prevHourtime.getMonth() + 1 < 10
          ? "0" + (prevHourtime.getMonth() + 1)
          : prevHourtime.getMonth() + 1; //得到月份
      data.prevhour.date =
        prevHourtime.getDate() < 10
          ? "0" + prevHourtime.getDate()
          : prevHourtime.getDate(); //得到日期
      data.prevhour.hour =
        prevHourtime.getHours() < 10
          ? "0" + prevHourtime.getHours()
          : prevHourtime.getHours(); //得到小时数
      data.prevhour.minute =
        prevHourtime.getMinutes() < 10
          ? "0" + prevHourtime.getMinutes()
          : prevHourtime.getMinutes(); //得到分钟数
    };
    // 获取天气
    // const getWeather = () => {
    //   getWeatherHttp(57071).then((res) => {
    //     const weatherData = res.data;
    //     const city = weatherData.location.name;
    //     const temperature = weatherData.now.temperature;
    //     data.weather.city = city;
    //     data.weather.temperature = temperature;
    //   });
    // };
    // 定时器每分钟请求一次
    const timeInserVal = setInterval(() => {
      getDate();
      // getWeather();
    }, 60000);

    // const groups = ref(sessionStorage.getItem("groups"));
    const changeMenuState = () => {
      data.menuIsOpen = !data.menuIsOpen
      context.emit('collapseMenu', data.menuIsOpen)
    };
    // 移除Tabs
    const removeTab = (targetName) => {
      if (data.TabData.length == 1) {
        router.push("/sys_index");
        return false;
      } else {
        const tabs = data.TabData;
        let activeName = data.TabActive;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              const nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        data.TabActive = activeName;
        data.TabData = tabs.filter((tab) => tab.name !== targetName);
        router.push(`/${props.modu}/${activeName}`);
        sessionStorage.setItem("tabsArray", JSON.stringify(data.TabData));
        if (data.TabData.length == 0 || data.TabData.length == 1) {
          data.contextMenuVisible = false;
        } else if (data.TabData.length > 1) {
          data.contextMenuVisible = true;
        }
      }
    };
    // 添加tabs
    const seleMenuItem = (path, text) => {
      const tabArr = data.TabData;
      const flag = tabArr.some((item) => {
        if (item.name == path) {
          data.TabActive = path;
          return true;
        }
      });
      if (!flag) {
        const obj = {
          title: text,
          name: path,
        };
        data.TabActive = path;
        data.TabData.push(obj);
        sessionStorage.setItem("tabsArray", JSON.stringify(data.TabData));
        if (data.TabData.length == 0 || data.TabData.length == 1) {
          data.contextMenuVisible = false;
        } else if (data.TabData.length > 1) {
          data.contextMenuVisible = true;
        }
      }
    };
    // 点击tabs
    const seleTabItems = (item) => {
      // const text = item.props.label;
      const path = item.props.name;
      data.activeNav = path;
      router.push(`/${props.modu}/${path}`);
    };
    //刷新时自动定位
    const reloadActive = () => {
      const activePath = window.location.hash.split("/");
      const len = activePath.length;
      if (activePath[len - 1] == "") {
        return false;
      }
      data.activeNav = activePath[len - 1];
      const tabArray = sessionStorage.getItem("tabsArray");
      const tabModu = sessionStorage.getItem("tabModu");
      const text = getText(data.activeNav);
      if (text) {
        if (tabModu == props.modu) {
          data.TabData = JSON.parse(tabArray);
          data.TabActive = activePath[len - 1];
          seleMenuItem(data.activeNav, text);
        } else {
          sessionStorage.setItem("tabModu", props.modu);
          sessionStorage.setItem("tabsArray", null);
          data.TabData = [
            {
              title: text,
              name: data.activeNav,
            },
          ];
          sessionStorage.setItem("tabsArray", JSON.stringify(data.TabData));
          data.TabActive = data.activeNav;
        }
      }
    };
    // 根据路径获取文字
    const getText = (s) => {
      const str = s;
      const menu = props.menuList;
      const len = menu.length;
      for (let i = 0; i < len; i++) {
        const items = menu[i].items;
        const ilen = items.length;
        for (let z = 0; z < ilen; z++) {
          if (items[z].path == str) {
            return items[z].text;
          }
        }
      }
    };
    // 关闭所有
    const closeAll = () => {
      if (data.TabData.length == 1) {
        const groups = sessionStorage.getItem("groups");
        if (groups === "税务") {
          return false;
        } else {
          router.push("/sys_index");
          return false;
        }
      } else {
        const tabs = data.TabData;
        data.TabData = tabs.filter((tab) => tab.name == "home");
        router.push(`/sys_index`);
        sessionStorage.setItem("tabsArray", JSON.stringify(data.TabData));
      }
    };
    // 关闭其他
    const closeOther = () => {
      if (data.TabData.length == 1) {
        const groups = sessionStorage.getItem("groups");
        if (groups === "税务") {
          return false;
        } else {
          router.push("/sys_index");
          return false;
        }
      } else {
        const tabs = data.TabData;
        data.TabData = tabs.filter((tab) => tab.name == data.TabActive);
        sessionStorage.setItem("tabsArray", JSON.stringify(data.TabData));
      }
      data.contextMenuVisible = false;
    };
    // 启动页面
    const isReload = () => {
      reloadActive();
    };
    watchEffect(() => {
      data.navList = props.menuList;
    });
    const toback = () => {
      router.back()
    }
    onMounted(() => {
      sessionStorage.setItem("tabsArray", JSON.stringify(data.TabData));
      getDate();
      isReload();
      if (data.TabData.length == 0 || data.TabData.length == 1) {
        data.contextMenuVisible = false;
      } else if (data.TabData.length > 1) {
        data.contextMenuVisible = true;
      }
    });
    onBeforeUnmount(() => {
      clearInterval(timeInserVal);
    });
    watch(
      () => route.params,
      () => {
        reloadActive();
      }
    );
    return {
      userName,
      store,
      ...toRefs(data),
      removeTab,
      seleMenuItem,
      seleTabItems,
      reloadActive,
      toback,
      getDate,
      timeInserVal,
      changeMenuState,
      closeAll,
      closeOther,
      getLogoSrc
    };
  },
};
</script>


<style scoped>
.LeftNavWrap{
  background: rgb(66, 167, 169);
}
.toggle-button{
 background-color: rgb(66, 167, 169);
 font-size:10px;
 line-height:24px;
 color:#fff;
 text-align: center;
 letter-spacing: 0.2em;
 cursor:pointer;
}
.LeftNav {
  width: 220px;
  height: calc(100vh - 60px);
  /* background-image: linear-gradient(rgba(60,195,250,1),rgba(68,198,230,1)); */
  /* background: rgb(34, 38, 83); */
  background: rgb(66, 167, 169);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.home {
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  padding-left: 33px;
  /* background-image: linear-gradient(rgb(80,140,255),rgb(70,90,240)) !important; */
  /* height: 60px; */
}
.home a {
  text-decoration: none;
  color: white;
}
.left_nav_content {
  width: 221px;
}
.left_nav_content /deep/.el-menu-item {
  background: none;
}

.left_nav_content /deep/ .el-sub-menu__title {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding-right:30px;
}
 .left_nav_content > .is-active /deep/ .el-sub-menu__title {
  background-image: radial-gradient(circle,rgb(46,112,123),rgb(56,143,145)) !important;
  color:white !important;
}

 .left_nav_content /deep/ .el-sub-menu__title:hover {
  background-image: radial-gradient(circle,rgb(46,112,123),rgb(56,143,145)) !important;
}

.el-menu-item.is-active {
  font-weight: bold !important;
  font-size: 16px;
}
.userInfo {
  margin-top: 90px;
  margin-bottom: 15px;
  justify-content: center;
}
.userInfo > img {
  display: block;
  border-radius: 60px;
  margin: 0 auto;
}
.userInfo p {
  font-size: 18px;
  /* color: white; */
  color: white;
  text-align: center;
  margin-top: 12px;
}
.userInfo .userInfo_Line {
  height: 2px;
  /* border-bottom: 2px dashed white; */
  width: 130px;
  margin: 15px auto 0 auto;
}
.navLines {
  width: 3px;
  height: 20px;
  background: white;
  margin-right: 10px;
}
.tablesWrap {
  position: absolute;
  /* left: 250px; */
  top: 70px;
  width: calc(100% - 260px);
  display: flex;
  overflow: auto;
}
::v-deep .el-tabs {
  width: 1585px;
}
::v-deep .el-tabs__content {
  overflow: visible;
}
::v-deep .el-tabs__item {
  color: rgb(46,112,123);
  background: #ffffff;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  margin-left: 10px;
  padding: 0 10px !important;
}
::v-deep .el-tabs__item .is-icon-close {
  top: 3px;
}
::v-deep .el-tabs__item.is-active {
  color: rgb(46,112,123);
  border-bottom: 2px solid rgb(46,112,123);
}
::v-deep .el-tabs__item:hover{
  color: rgb(46,112,123);
}
::v-deep .el-icon-arrow-left {
  color: white;
}
::v-deep .el-icon-arrow-right {
  color: white;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__active-bar {
  background: none !important;
}
.el-menu-item.is-active {
  font-weight: 800 !important;
  font-size: 15px;
}
/* 首页登录时间、天气等信息 */
.logintime {
  color: white;
  text-align: center;
  line-height: 20px;
  margin-top: 10px;
}
.weatherWraper {
  color: white;
  text-align: center;
  line-height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
/* 快捷键 */
.shortcutkey {
  width: 100%;
}
.shortcutkey > div {
  margin-top: 15px;
}
.r_title {
  display: flex;
  align-items: center;
  
  padding: 0 20px;
  color: white;
  font-size: 18px;
  line-height: 56px;
}
.shortcutkey .r_title:hover {
  background: #66babd;
}
.shortcutkey .r_title a {
  font-size: 12px;
  position: relative;
  top: 0px;
  /* left: 52px; */
  color: white;
  cursor: pointer;
}
.shortcutkey .r_title a:hover {
  color: white;
}
.helper .helper_p {
  padding: 0 30px;
  margin-top: 30px;
}
/* 详情列表样式 */
.shortcutkey > div ul {
  padding: 0px 30px;
}
.shortcutkey > div ul li {
  font-size: 14px;
  line-height: 50px;
  color: white;
}

.el-menu-item{
  color: white;
  
}

.r_title {
  display: flex;
  align-items: center;
  /* padding: 0 20px; */
  color: white;
  line-height: 55px;
  text-align: center;
  /* font-weight: 600; */
  font-size: 17px;
}
.r_title:hover {
  /* background: url('/src/assets/img/lefts.png'); */
  /* background-image: url('/src/assets/img/lefts.png'); */
  /* margin-left: -50px; */
  background-image: radial-gradient(circle,rgb(46,112,123),rgb(56,143,145)) !important;
  /* background: image('/src/assets/img/lefts.png'); */
}
.r_title a {
  font-size: 16px;
  position: relative;
  top: 0px;
  left: 12px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
 .tup{
  width: 20px;
  height: 80rpx;
  margin-left:20px;
}
.r_title a:hover {
  color: white;
}
.bg_div {
  width: 35px;
  height: 35px;
  text-align: center;
  background: #fff;
  border-radius: 3px;
}
.glyphicon {
  line-height: 35px;
  /* margin-left: 10px; */
}

.toback{
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  padding-left: 33px;
  position:absolute;
  bottom:20px;
  display: block;
}
.el-icon svg{
  margin-left:20px !important;
  color:red;
}
</style>

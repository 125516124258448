<template>
  <div class="login">
    <div class="logo"></div>
    <div class="fromWrap">
      <div class="loginWrap">
        <img class="welcome_login" src="@/assets/img/welcome.png" alt="" />
        <div class="loginWay">
          <div :class="ispsw ? 'cur' : 'item11'" @click="pws_login()">
            账号登录
          </div>

          <div :class="isyzm ? 'cur' : 'item11'" @click="yzm_login()">
            短信登录
          </div>
          <div :class="iswx ? 'cur' : 'item11'" @click="wx_login()">
            扫码登录
          </div>
        </div>
        <el-form
          :model="LoginFromData"
          ref="Login"
          :rules="LoginRules"
          class="from_wrap"
          label-position="left"
          size="small"
          hide-required-asterisk
        >
          <template v-if="ispsw">
            <el-form-item prop="username">
              <div class="input1">
                <img src="@/assets/img/username.png" />
                <el-input
                  v-model="LoginFromData.username"
                  placeholder="请输入用户名"
                  @keyup.enter="submitForm('Login', $event)"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <div class="input1">
                <img src="@/assets/img/password.png" />
                <el-input
                  clearable
                  v-model="LoginFromData.password"
                  placeholder="请输入密码"
                  show-password
                  @keyup.enter="submitForm('Login', $event)"
                ></el-input>
              </div>
            </el-form-item>
            <!-- <el-form-item prop="checkCode"> -->
            <el-form-item prop="checkCode" class="captch_form_item">
              <div class="input1">
                <img src="@/assets/img/username.png" />
                <el-input
                  clearable
                  v-model="LoginFromData.checkCode"
                  placeholder="请输入验证码"
                  @keyup.enter="submitForm('Login', $event)"
                ></el-input>
                <img
                  :src="captchImgUrl"
                  alt="验证码"
                  @click="getCaptcha()"
                  class="captchImg"
                />
              </div>
            </el-form-item>
            <div style="height: 30px; line-height: 30px">
            <span class="forgetPsw" @click="forgetPsw">忘记密码？</span>
          </div>
          </template>
          <template v-if="isyzm">
            <el-form-item prop="phone">
              <div class="input1">
              <el-input
                clearable
                v-model="LoginFromData.phone"
                placeholder="手机号"
                @keyup.enter="submitForm('Login', $event)"
              ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="code" class="phone_form_item">
              <div class="input1">
              <el-input
                clearable
                v-model="LoginFromData.code"
                placeholder="手机验证码"
                @keyup.enter="submitForm('Login', $event)"
              ></el-input>
              <el-button
                class="captchImg"
                :disabled="disabled"
                @click="sendCode"
                >{{
                  codeNum == 60 ? "发送验证码" : `(${codeNum})s后重试`
                }}</el-button
              >
              </div>
            </el-form-item>
          </template>
          
          <!-- <el-button
              class="submit"
              type="primary"
              @click="submitForm('Login')"
              round
              >登录</el-button -->
          <img v-if="!iswx"
            class="tologin"
            src="@/assets/img/loginimg.png"
            @click="submitForm('Login')"
          />
        </el-form>
      </div>
    </div>
    <div class="CopyRight">
      <p>ICP备案号：豫ICP备2023017088号</p>
      <!-- <b>孟津区城市管理局</b>
      <span>All Rights Reserved</span> -->
      <!-- <p></p> -->
      <p>
        平台系统技术支持：郑州向为智能科技有限公司 &nbsp;&nbsp;
        服务热线：0371-55969586 18939258029
      </p>
    </div>
  </div>
</template>
<style scoped>
@media all and (min-width: 769px) {
  .login {
    position: relative;
    top: 0;
    overflow: hidden;
    height: 100vh;
    background: url("../assets/img/login_bg.png");
    background-position: 0px 0px;
    background-size: 100% 100%;
  }
  .beijt {
    width: 100%;
    height: 100%;
  }
  .remaberPassword {
    text-align: right;
  }
  /* .logo {
    background: url('../assets/img/logintext.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    width: 46%;
    height: 80px;
    top: 24%;
    left: 8%;
    text-align: center;
  } */
  .fromWrap {
    position: absolute;
    bottom: 12%;
    right: 10%;
    display: flex;
  }

  .fromWrap .loginWrap {
    width: 2.8rem;
    height: 3.3rem;
    background-color: white;
    box-shadow: 0px 0px 10px #006c6d;
  }
  .welcome_login {
    margin: 20px 0 0 33%;
    width: 34%;
  }
  .loginWay {
    display: flex;
    font-size: 20px;
    margin: 40px;
    color: rgb(152, 152, 152);
    line-height: 50px;
  }
  .loginWay div {
    margin-left: 20px;
    cursor: pointer;
  }
  .loginWay .cur {
    border-bottom: 2px solid #279baf;
    color: rgb(69, 69, 69);
    font-weight: bold;

  }
  .input1 {
    background-image: url("../assets/img/input.png");
    width: 88%;
    margin-left: -6px;
    height: 60px;
    display: flex;
    justify-content: left;
  }
  .input1 img {
    width: 60px;
    height: 100%;
  }

  .fromWrap .loginWrap .el-form-item {
    margin-left: 60px;
    margin-top: 30px;
  }
  .fromWrap .loginWrap .el-form-item /deep/ input {
    border: none;
    background: none;
    width: 338px;
    font-size: 20px;
    height: 100%;
  }

  .fromWrap .loginWrap .captch_form_item .captchImg {
    width: 110px;
    height: 50px;
    position: absolute;
    right: 65px;
    top: 5px;
    cursor: pointer;
  }
  .fromWrap .loginWrap .phone_form_item .captchImg {
  width: auto;
  height: 54px;
  font-size: 16px !important;
  color: #409eff;
  border: none;
  background: none;
  position: absolute;
  right: 60px;
  top: 0px;
  cursor: pointer;
}
  .tologin {
    width: 80%;
    margin: 0 10%;
  }
  .CopyRight {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
    bottom: 1px;
    color: #fff;
  }
  .CopyRight div {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }

  .CopyRight div > span {
    line-height: 36px;
  }

  .CopyRight img {
    width: 48px;
    margin-left: 20px;
  }
  ::v-deep(.CopyRight > b) {
    font-size: 16px;
    padding: 0 14px !important;
  }
  .forgetPsw {
    float: right;
    margin-right: 12%;
    color: grey;
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .login {
    position: relative;
    top: 0;
    overflow: hidden;
    width: 10rem;
    height: 100vh;
    background: url("../assets/img/login_title.png");
    background-position: -3.1rem -1rem;
    background-size: 229vw 105vh;
  }
  /* .beijt {
  width: 220vw;
  height: 100vh;
} */
  .remaberPassword {
    text-align: right;
  }
  .logo {
    width: 8rem;
    height: 1.3rem;
    margin: 2.2rem 1rem 0 1rem;
    text-align: center;
    background: url("../assets/img/logintext_m.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .fromWrap {
    /* width: 1165px; */
    width: 8rem;
    height: 8rem;
    margin: 0.7rem auto;
    /* position: absolute;
  top: 24%;
  left: 4%; */
    display: flex;
  }

  .fromWrap .loginWrap {
    width: 8rem;
    height: 8rem;
    background-color: white;
    box-shadow: 0px 0px 0.625rem #006c6d;
    border-radius: 0.4rem;
  }
  .welcome_login {
    margin: 0.6rem 0 0.3rem 33%;
    width: 34%;
  }
  .input1 {
    background-image: url("../assets/img/input.png");
    width: 88%;
    margin: 0rem 0rem 0rem 0.1rem;
    height: 1rem;
    display: flex;
    justify-content: left;
  }
  .input1 img {
    width: 1rem;
    height: 1rem;
  }

  .fromWrap .loginWrap .el-form-item {
    margin-left: 0.8rem;
    /* margin-top: 1.875rem; */
    /* height: 0.5rem; */
    margin-bottom: 0.3rem;
  }
  .fromWrap .loginWrap .el-form-item /deep/ input {
    border: none;
    background: none;
    width: 5.2rem;
    font-size: 0.4rem;
    height: 100%;
  }

  .fromWrap .loginWrap .captch_form_item .captchImg {
    width: 2rem;
    height: 1rem;
    position: absolute;
    right: 0.8rem;
    top: 0px;
    cursor: pointer;
  }
  .tologin {
    width: 6rem;
    margin: 0 1rem;
  }
  .forgetPsw {
    float: right;
    margin-right: 12%;
    color: grey;
    font-size: 0.4rem;
  }
  .CopyRight {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
    bottom: 1px;
    color: #fff;
  }
  .CopyRight div {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }

  .CopyRight div > span {
    line-height: 36px;
  }

  .CopyRight img {
    width: 48px;
    margin-left: 20px;
  }
  ::v-deep(.CopyRight > b) {
    font-size: 16px;
    padding: 0 14px !important;
  }
}
.el-message-box {
  max-width: 80%;
}
</style>

<script>
import { Login, search_pagepermission, captcha } from "@/request/api";
import { onMounted } from "vue";
import { ref, reactive } from "@vue/reactivity";
import { ElMessageBox } from "element-plus";
// import axios from "axios";
import JSEncrypt from "jsencrypt";
import { useRouter } from "vue-router";
const Base64 = require("js-base64").Base64;
export default {
  name: "Login",

  setup() {
    const router = useRouter();
    // const JSEncryptF = JSEncrypt;
    // 用户登录数据
    const LoginFromData = reactive({
      username: "",
      password: "",
      checkCode: "",
      checked: false,
      allList: [],
      screenWidth: "",
      ispsw: true,
      isyzm: false,
      iswx: false,
    });

    // 登录校验
    const LoginRules = ref({
      username: [
        {
          required: true,
          message: "用户名不能为空",
        },
      ],
      password: [
        {
          required: true,
          message: "密码不能为空",
        },
      ],
      checkCode: [
        {
          required: true,
          message: "校验码不能为空",
        },
      ],
    });
    let captchImgUrl = ref("");
    let captchaKey = ref("");
    const disabled = ref(false);//发送验证码按钮点击
    const codeNum = ref(60);//验证码倒计时
    let ispsw = ref(true); //登录方式true密码 false手机
    let isyzm = ref(false); //登录方式true密码 false手机
    let iswx = ref(false); //登录方式true密码 false手机
    const clearId = ref();//定时任务
    const getCaptcha = () => {
      captcha().then((res) => {
        console.log(res, "---");
        captchImgUrl.value = `${res.image_data}`;
        captchaKey.value = `${res.result}`;
      });
    };
    // 发送验证码
    const sendCode = async () => {
      if (disabled.value || codeNum.value != 60) return;
      disabled.value = true;
      // const res = await phonecode({ phone: LoginFromData.phone });
      clearId.value = setInterval(() => {
        codeNum.value--;
        if (codeNum.value == 0) {
          clearInterval(clearId.value);
          codeNum.value = 60;
          disabled.value = false;
        }
      }, 1000);
      // console.log(res);
    };
    const isChecked = () => {
      const username = localStorage.getItem("username");
      if (username) {
        LoginFromData.username = localStorage.getItem("username");
        LoginFromData.password = Base64.decode(
          localStorage.getItem("password")
        );
        LoginFromData.checked = true;
      }
    };

    // 顶部
    // 获取顶部导航
    const fetchPowerData = () => {
      const dataa = {
        keyword: "",
        current_page: 1,
        page_size: 100,
      };
      search_pagepermission(dataa).then((res) => {
        if (res) {
          LoginFromData.allList = res.data;
          arr2Json(LoginFromData.allList);
        }
      });
    };
    const arr2Json = (list) => {
      const perm_mod = [];
      const pageList = [];
      const pageIDs = sessionStorage.getItem("perm_list").split(",");
      pageIDs.forEach((ids) => {
        list.forEach((all) => {
          if (all.id == ids) {
            pageList.push(all);
          }
        });
      });
      const a = pageList.map((a) => a.perm_mod);
      const parent = Array.from(new Set(a));
      console.log(pageList, "pageListpageList");
      console.log(parent, "parentparentparent");
      pageList.forEach((l) => {
        parent.forEach((p) => {
          if (l.perm_mod == p) {
            perm_mod.push({
              label: l.perm_mod,
              path: l.grand_parent_path,
            });
          }
        });
      });
      const uniqueArr = Object.values(
        perm_mod.reduce((prev, cur) => {
          prev[cur.label] = cur;
          return prev;
        }, {})
      );
      console.log(uniqueArr, "22334455");
      sessionStorage.setItem("uniqueArr", JSON.stringify(uniqueArr)); // 设置uniqueArr
      if (LoginFromData.screenWidth > 768) {
        router.push("/sys_index/home");
        // this.$router.push({ path: "/sys_index/home" });
      } else {
        // this.$router.push({ path: "/mobile_index" });
        router.push("/mobile_index");
      }
    };

    // 加密
    const rasEncryption = (content) => {
      // var PUBLIC_KEY =  '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtnTsun0uFwxBTkuCKNdb\nx1a6RkmaKe+HomNc4JuYxQmcWjEwYZrh8evbdsRoJFUZGuiYr73izse1FbrJKfUf\nvOWvP3GayDgqVf+/1R9Rges9/XyOV0d5CrqZ2B6AGYU6ZTn9wHcQwUDvV+c0DFCN\npr3GTL4at6TZCwkxAUSfSsAHIqlcjj1tCg1UwuelAG3AVxkggCUCNEfoavlanvm0\n0ecG09OeN/ltbLzfZyFBNqXN/+BGHWU/z0utqRuFTeBaynLAjHisxt0VV+tvR3F5\njbu1zm2AzyHlbH7z39n4KyIZnD/3Ocf0qtSzsNYlHoVgmn2UOWlEWSP/CrGLQnn+\nawIDAQAB\n-----END PUBLIC KEY-----';
      //私钥
      var PRIVATE_KEY =
        "-----BEGIN RSA PRIVATE KEY-----\nMIIEowIBAAKCAQEAtnTsun0uFwxBTkuCKNdbx1a6RkmaKe+HomNc4JuYxQmcWjEw\nYZrh8evbdsRoJFUZGuiYr73izse1FbrJKfUfvOWvP3GayDgqVf+/1R9Rges9/XyO\nV0d5CrqZ2B6AGYU6ZTn9wHcQwUDvV+c0DFCNpr3GTL4at6TZCwkxAUSfSsAHIqlc\njj1tCg1UwuelAG3AVxkggCUCNEfoavlanvm00ecG09OeN/ltbLzfZyFBNqXN/+BG\nHWU/z0utqRuFTeBaynLAjHisxt0VV+tvR3F5jbu1zm2AzyHlbH7z39n4KyIZnD/3\nOcf0qtSzsNYlHoVgmn2UOWlEWSP/CrGLQnn+awIDAQABAoIBACKK9WyDGZA8fjqr\n+frwdsxBA6gd9CVfV17rj+BoJN6c6LB8oovADmnGPPj5yzuhrsyU359aBFAgqAxy\nnfYnFbimBW0t/V9NntpEPpXDRaOinUxZ4frtKaHZ3Rl5AMiUHA+hEIT5Jb9oo0eR\nmp2APKkmQZ4y2Y0OFRdNSK1USBIXAgIgdOinmh1x+MxobE3B6ewiSPIkOmPs+/TO\ne6cSt+JlKWaVMAMEu3M51mUZiBOJm96uPqY+1RPFgFWcsbfacPm8G12lUOXkct80\ninhIhmSN0N8ryJoM3wlLup2NjpdnXKxyikek3w0KUFeotKH1bRAXdjkMvnvz7Vgd\neZXvDj0CgYEA0S4fNLrMFUg+tA7sezkXFd70Ezycmtik8z4JPIWQU2+K9ONHGR+g\n15NjgFUmKZUa5QD6iqf0cj+9evRseU2dHyYCK4RIud21B7SsZOJdVo9XiLevoOw5\nXr9Vnlr7vYdsiQq6Ea1T67uYEnb2v7HaFDQMou8QmEN6iyNq29buy40CgYEA30uR\nrOouA0aLs1uDj73dGCwb6O5gzxiNTl/8BgJrT3kDbQxr/ZNNLRZijOgzf5kB1r14\nAtu847/DGRvOKhpmpGax3KSPA1ckYrFXFZoddYMzF67FOXdxi8JIqqdp+KLn0GOm\n9LMVuAkc7hqfmq8ysI0Dct+tFG4q6qyxfxmo99cCgYAbQGD8Y6GW3DSfDJquav+g\n78zofKsCWVsT/W8CnXvyAsQdp0Zp9X7k1uTNoUo5fJ1mXDxHuB3WjItI63L+Uqg3\nmKILvU4kOMgO2vDiq8LxNfd3bkbJNj07JL4oxT2qsWvoZqdbCjO2sFfdf/YhfzAk\nFavXxkFpNNFQMysGvPebOQKBgF8FuJNeAICyV6bWzdCwmlK8CpCASMA4kYGFxSkF\nhIcIV1XjkF0TfyctvHh8I9Yr1aV9cYG94s8EftDDjWNLYtyZOx1LBfUE813wW7+8\nmsa0M1zjqdg/pRk1b1j371e4c2+xyI2fXCG59w64U60u2yJYj9wlpXDpROgi31HY\nKJh3AoGBAJUp0BrbKCopLTHj/X0kS6X2I84zYEFrT3to9VSXaGHfqHlQ/40AlXNk\nU/mrlHW3CWbhybQjo4VJhMaYEVzgMXs5Hvz4C88cNjgqC7JQjoWsM5Di72ALEPLO\nzWHfYGZ4Q65Eka12ES10mFUAncqCpYa74msVa2wnpn+1nH8W7NLA\n-----END RSA PRIVATE KEY-----";
      //使用公钥加密
      var encrypt = new JSEncrypt();
      encrypt.setPrivateKey(
        "-----BEGIN RSA PRIVATE KEY-----" +
          PRIVATE_KEY +
          "-----END RSA PRIVATE KEY-----"
      );
      var encrypted = encrypt.encrypt(JSON.stringify(content));
      // console.log('加密前数据:%o', content);
      // console.log('加密后数据:%o', encrypted);
      //使用私钥解密
      var decrypt = new JSEncrypt();
      //decrypt.setPublicKey('-----BEGIN PUBLIC KEY-----' + PUBLIC_KEY + '-----END PUBLIC KEY-----');
      decrypt.setPrivateKey(
        "-----BEGIN RSA PRIVATE KEY-----" +
          PRIVATE_KEY +
          "-----END RSA PRIVATE KEY-----"
      );
      // var uncrypted = decrypt.decrypt(encrypted);
      // console.log('解密后数据:%o', uncrypted);
      return encrypted;
    };
    const forgetPsw = () => {
      ElMessageBox.confirm("忘记密码请联系管理员！", "警告", {
        type: "info",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      });
    };
    const pws_login = () => {
      ispsw.value = true;
      isyzm.value = false;
      iswx.value = false;
    };
    const yzm_login = () => {
      ispsw.value = false;
      isyzm.value = true;
      iswx.value = false;
    };
    const wx_login = () => {
      ispsw.value = false;
      isyzm.value = false;
      iswx.value = true;
    };
    onMounted(() => {
      getCaptcha();
      isChecked();
    });
    return {
      LoginFromData,
      LoginRules,
      captchaKey,
      captchImgUrl,
      getCaptcha,
      rasEncryption,
      isChecked,
      forgetPsw,
      fetchPowerData,
      ispsw,
      isyzm,
      iswx,
      pws_login,
      wx_login,
      yzm_login,
      sendCode,
      disabled,
      codeNum
    };
  },
  methods: {
    submitForm(formName, env) {
      if (env) {
        env.target.blur();
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.LoginFromData.checked) {

          localStorage.setItem("username", this.LoginFromData.username);
          localStorage.setItem(
            "password",
            Base64.encode(this.LoginFromData.password)
          );
          //  } else {
          //    localStorage.removeItem('username')
          //    localStorage.removeItem('password')
          //  }
          // const userInfo = this.rasEncryption({
          const userInfo = {
            username: this.LoginFromData.username,
            password: this.LoginFromData.password,
          };
          console.log(userInfo, "");
          const LoginData = {
            token: userInfo,
            verify_code: this.LoginFromData.checkCode,
            correct_code: this.captchaKey,
          };
          console.log(LoginData, "LoginData");
          const id_list = [];
          Login({
            username: userInfo.username,
            password: userInfo.password,
            // captcha_result: LoginData.verify_code,
            verify_code: LoginData.correct_code,
            correct_code: LoginData.verify_code,
            // captcha_key: LoginData.correct_code,
          }).then((res) => {
            if (res.code == 200) {
              res.perm_list.forEach((element) => {
                id_list.push(element.id);
              });
              sessionStorage.setItem("userID", res.id); // 设置token userid
              sessionStorage.setItem("name", res.username); // 设置token 真实姓名
              sessionStorage.setItem("user_perm_ids", res.user_perm_ids);
              sessionStorage.setItem("perm_list", id_list);
              this.LoginFromData.screenWidth = window.screen.width;
              this.fetchPowerData();
              // console.log(screenWidth);
            } else {
              this.getCaptcha();
              ElMessageBox.alert(res.msg, "消息提示", {
                confirmButtonText: "确认",
                // center: true,
              });
            }
          });
        }
      });
    },
  },
};
</script>

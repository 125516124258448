import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入elementUI 相关
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import VueJsonp from 'vue-jsonp'
// 删除默认样式
import "./assets/css/ResetCss.css";
import "./assets/css/public.css";
// 批量引入ICon
// import * as ElIconModules from "@element-plus/icons";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import "./assets/css/iconfont.css";
// 图表
import * as echarts from "echarts";
//pc适配
import "lib-flexible-computer";
// 弹窗拖动
import dialogDrag from './component/dialogDrag'
// 打印插件
const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
app.use(store);
app.use(router);
app.use(dialogDrag);
app.use(ElementPlus, { locale: zhCn });
app.use(VueJsonp);
app.mount("#app");

// createApp(App).use(store).use(router).use(ElementPlus).mount("#app");

// 添加全局路由守卫 用于判断是否登录
router.beforeEach((to, from, next) => {
  const NowUrl = to.path;
  // if (NowUrl === "/login" || NowUrl === "/account_data_charts" || NowUrl === "/taxclient/login") {
  if (NowUrl === "/login") {
    //  登录页面直接放行
    next();
    return true;
    
  } else {
    // 非登录页面 判断token 是否存在，若不存在跳转到登录页
    const token = sessionStorage.getItem("userID");
    console.log(token,'登录信息');
    if (token) {
      next();
      return true;
    } 
    // else {
    //   if (NowUrl.indexOf('taxclient') !== -1) {
    //     router.push({path: "/taxclient/login"})
    //   } 
    else {
        router.push({ path: "/login" });
      }
    }
  // }
});
// 统一注册el-icon图标
// for (let iconName in ElIconModules) {
//   app.component(transElIconName(iconName), ElIconModules[iconName]);
// }
// // 批量导入Icon
// function transElIconName(iconName) {
//   return "i" + iconName.replace(/[A-Z]/g, (match) => "-" + match.toLowerCase());
// }
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
   app.component(key, component)
  }


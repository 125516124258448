
<template>
  <div class="wrapper">
    <div class="baidu-map-echart" id="container"></div>
    <div>
      <div class="operateWrapper">
      
        <!-- <el-input v-model="searchAdress.text" size="large" placeholder="查询地址" /> -->
        <!-- <el-button type="primary" class="searchBtn" @click="searchAction">搜索</el-button> -->
        <!-- <div :class="{'boxSelect': true, 'boxActive': reactData.openDraw }" @click="toggleDraw">
              <div class="box" />
            </div> -->
      </div>
      <!-- <div style="width:400px; height:800px; background:red;">
        <el-radio-group style="margin-bottom: 30px;">
          <el-radio-button label="top">流量热力</el-radio-button>
          <el-radio-button label="right">生活用水</el-radio-button>
          <el-radio-button label="bottom">工业用水</el-radio-button>
        </el-radio-group>
        <el-tabs :tab-position="tabPosition" style="height: 200px;">
              <el-tab-pane label="用户管理">用户管理</el-tab-pane>
              <el-tab-pane label="配置管理">配置管理</el-tab-pane>
              <el-tab-pane label="角色管理">角色管理</el-tab-pane>
              <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
            </el-tabs>
      </div> -->
    </div>
    
  </div>
</template>
<script>

// import BMap from 'BMap';
import { ref, onMounted, reactive } from "vue";
export default {
  setup() {
    // const searchAdress = reactive({
    //   text:'',
    // })

    const state = reactive({
      markerArrs: [  
                    { title: "1", point: "112.42010 , 34.83580"},  
                    { title: "2", point: "112.42330 , 34.83679"}, 
                    { title: "3", point: "112.42035 , 34.83699"},  
                    { title: "4", point: "112.42345 , 34.83580"}, 
                    { title: "5", point: "112.42055 , 34.83586"},  
                    { title: "6", point: "112.42360 , 34.83690"}, 
                    { title: "7", point: "112.42070 , 34.83679"},  
                    { title: "8", point: "112.42055 , 34.83589"}, 
                    { title: "9", point: "112.42083 , 34.83349"},  
                    { title: "10",point: "112.42399 , 34.83999"}, 
                    { title: "11",point: "112.42350 , 34.83420"}, 
                    { title: "12",point: "112.42351 , 34.83549"}, 
                    { title: "13",point: "112.42530 , 34.83609"}, 
                    { title: "14",point: "112.42532 , 34.83879"}, 
                    { title: "15",point: "112.42680 , 34.83669"}, 
                    { title: "16",point: "112.42780 , 34.83759"}, 
                    { title: "17",point: "112.45380 , 34.8379"}, 
                ]
    })
    
   
    // 自定义地图样式
    // let timer = ref(0);
    // // 初始化地图
    // let map = null;
    // function initMapGL() {
    //   const centerPoint = new BMap.Point(112.42080 , 34.83579 );
    //   map = new BMap.Map("container",{ enableMapClick: false });
    //   map.centerAndZoom(centerPoint, 16);
    //   map.setMinZoom(8); // 级别越大看的越详细
    //   map.setMaxZoom(17);
    //   map.enableScrollWheelZoom();

    // }
    
    

    // 中文搜索定位
 
    // } 

    // 添加标注  
    // function addMarker(point, index) {
    //   index = 11;
    //   var myIcon = new BMap.Icon(
    //     new BMap.Size(23, 25), {
    //     offset: new BMap.Size(10, 25),
    //     imageOffset: new BMap.Size(0, 0 - index * 25)

    //   });
    //   var marker = new BMap.Marker(point, { icon: myIcon });
    //   map.addOverlay(marker);
    //   return marker;
    // }
    // 异步调用百度js  
    const map_load = ()=> {
      var load = document.createElement("script");
      load.src = "https://api.map.baidu.com/location/ip?ak=Wzjog44BEZPPOLLX5GbDEhHwguVWVLEK&coor=bd09ll";
      document.body.appendChild(load);
      console.log(load,'loadload');
    }

   
    // function drawPolyline(bMap, points) {
    //   if (points == null || points.length <= 1) {
    //     return;
    //   }
    //   bMap.addOverlay(new BMap.Polyline(points, {
    //     strokeColor: "blue",
    //     strokeWeight: 3,
    //     strokeOpacity: 0.6
    //   })); // 画线  

    // }  

         
    onMounted(() => {
      // initMapGL(); // 生成地图
      // windowRun(); 
      // map_init()
      map_load()
      // addOverlay()
      // terminalPoint()
      // addStartMarker()
      // addMarkerEnd()
      // drawPolyline()
    });
    return {
      map_load
      // searchAdress,
      // searchAction,
      // terminalPoint
      
      
    };
  }
};
</script>
<style scoped>
  .wrapper {
    height: 100%;
    position: relative;
  }
  .baidu-map-echart {
    height: 100%;
  }
  .operateWrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
  }
  .searchBtn {
    margin-left: 10px;
    /* margin-top: 5px; */
    width: 50px;
    height: 40px;
  }

  .infowindow .sprite{
    background-color: red;
  }
</style> 

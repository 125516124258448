<template>
  <div id="ZHCDZ">
    <div v-if="$route.meta.keepAlive == 1">
      <ZHCDZhead></ZHCDZhead>
    </div>
    <router-view></router-view>
    <div v-show="isShowTips" class="tips" @click="showTips">
      <img src="@/assets/img/icon-tips.png" alt="" style="width: 30px;margin-bottom: 5px;">
      提示
    </div>

    <el-dialog v-model="dialogVisible" title="操作提示" width="960px">
      <el-timeline style="">
        <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
          :type="activity.type" :color="activity.color" :size="activity.size" :hollow="activity.hollow"
          :timestamp="activity.timestamp">
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import ZHCDZhead from "@/component/header.vue";
import { MoreFilled } from '@element-plus/icons-vue'
export default ({
  components: {
    ZHCDZhead,
  },
  data() {
    return {
      isShowTips: false,
      dialogVisible: false,
      activities: [
        {
          content: '添加充电站',
          timestamp: '在设备管理模块添加充电站信息。注：添加充电站时需正确输入站点的经纬度信息，方便小程序用户在小程序中查找到此充电站。',
          size: 'large',
          type: 'primary',
          icon: MoreFilled,
        },
        {
          content: '绑定充电桩',
          timestamp: '管理员会手动分配充电桩到每个运营商，运营商在创建好充电站后，在充电站列表右侧点击“绑定充电桩”按钮，弹出充电桩列表，进行勾选绑定。',
          color: '#0bbd87',
        },
        {
          content: '添加费用模板',
          timestamp: '在费用管理模块，点击添加费用模板按照要求选择电动车或电动汽车，然后选择计费模式与金额完成添加。',
        },
        {
          content: '绑定计费模板',
          timestamp: '在充电站管理页面，充电站右侧点击“配置计费”按钮，在弹窗中选择计费模板进行绑定，点击提交完成绑定后小程序用户即可开始进行充电。',
          type: 'primary',
          hollow: true,
        }
      ]
    }
  },
  watch: {
    '$route':  {
      handler(to) {
        if(to.path === '/' || to.path === '/login') {
          this.isShowTips = false
        } else {
          this.isShowTips = true
        }
      },
      immediate: true
    }
  },
  methods: {
    showTips() {
      this.dialogVisible = true
    }
  }
})
</script>

<style>
html {
  background-color: #f5f5f5;
}

html,
body,
#app,
#ZHCDZ {
  width: 100%;
  height: 100%;
}

#ZHCDZ {
  min-width: 1340px;
  min-height: 600px;
  position: relative;
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
}

.tips {
  position: fixed;
  right: 14px;
  bottom: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 1px 4px -2px rgba(0, 0, 0, .13), 0 2px 8px 0 rgba(0, 0, 0, .08), 0 8px 16px 4px rgba(0, 0, 0, .04);
  font-size: 12px;
  background-color: #E3F9F9;
  cursor: pointer;
  z-index: 999;
}

.tips:hover {
  background-color: #adf0f0;
}

.el-timeline-item__content {
  font-size: 15px;
}

.el-timeline-item__timestamp {
  font-size: 14px !important;
}
</style>

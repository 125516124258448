import { createRouter, createWebHashHistory } from "vue-router";
// 登录页面
import Login from "../views/login.vue";
// 系统首页
import SYSIndex from "../views/sysIndex/index.vue";
// 用户信息
import UserInfo from "../views/userInfo.vue";
// 用户管理
import Usermanage from "../views/userManage/usermanage.vue";
// 订单管理
import Ordermanage from "../views/orderManage/ordermanage.vue";
// 设备管理Ordermanage
import DeviceManage from "../views/deviceManage/devicemanage.vue";
// 计费模板
import feeTemplate from "../views/feeTemplate/feetemplate.vue";
// // 报表管理
import reportManage from "../views/reportManage/reportmanage.vue";
// 信息面板
import Gis from "../views/GIS/gis.vue";
// // 消息管理
// import News from "../views/news/newsmanage.vue";
// 营收管理
import RevenueManage from "../views/revenueManage/revenueManage.vue";
// 营收管理
import FaultManage from "../views/faultManage/faultmanage.vue";


// // 资料管理
// import SanitationCar from "../views/sanitationCar/sanitation_car.vue";
// 流量卡管理
// import SmsManage from '../views/smsManage/sms_manage.vue';
// // 信息管理
// import SmsManage from '../views/smsManage/sms_manage.vue';
// // 优惠活动
// import SmsManage from '../views/smsManage/sms_manage.vue';
// // 系统管理
import Setting from '../views/Setting/setting.vue';
// 移动端
import mobileIndex from "../views/mobileDesign/mobile_index.vue";

const routes = [
  {
    path: "/",
    redirect: "/sys_index/home",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/userInfo",
    name: "userInfo",
    component: UserInfo,
  },
  // 首页
  {
    path: "/sys_index",
    name: "SYSIndex",
    component: SYSIndex,
    redirect: "/sys_index/home",
    children: [
      {
        path: "/sys_index/home",
        component: () => import("../views/sysIndex/home.vue"),
      },

    ],
  },


  // 用户管理
  {
    path: "/user_manage",
    name: "Usermanage",
    component: Usermanage,

    redirect: "/user_manage/search_man",
    children: [

      {
        path: "/user_manage/search_man",
        component: () => import("../views/userManage/search_man.vue"),
      },
      // {
      //   path: "/user_manage/user_add",
      //   component: () => import("../views/userManage/user_add.vue"),
      // },
      {
        path: "/user_manage/user_edit",
        component: () => import("../views/userManage/user_edit.vue"),
      },

      // {
      //   path: "/user_manage/user_action",
      //   component: () => import("../views/userManage/user_action.vue"),
      // },
      {
        path: "/user_manage/user_record",
        component: () => import("../views/userManage/user_record.vue"),
      },
      {
        path: "/user_manage/recharge_record",
        component: () => import("../views/userManage/recharge_record.vue"),
      },
      {
        path: "/user_manage/user_analyse",
        component: () => import("../views/userManage/user_analyse.vue"),
      },
      {
        path: "/user_manage/user_online",
        component: () => import("../views/userManage/user_online.vue"),
      },
    ],
  },
  {
    path: "/order_manage",
    name: "Ordermanage",
    component: Ordermanage,

    redirect: "/order_manage/order_current",
    children: [
      {
        path: "/order_manage/order_current",
        component: () => import("../views/orderManage/order_current.vue"),
      },
      {
        path: "/order_manage/order_history",
        component: () => import("../views/orderManage/order_history.vue"),
      },
      {
        path: "/order_manage/order_unusual",
        component: () => import("../views/orderManage/order_unusual.vue"),
      }
    ],
  },
  // 设备管理
  {
    path: "/device_manage",
    name: "DeviceManage",
    component: DeviceManage,

    redirect: "/device_manage/charg_manage-type=undefined",
    children: [
      {
        path: "/device_manage/station_search",
        component: () => import("../views/deviceManage/station_search.vue"),
      },
      {
        path: "/device_manage/station_manage",
        component: () => import("../views/deviceManage/station_manage.vue"),
      },
      {
        path: "/device_manage/station_add",
        component: () => import("../views/deviceManage/station_add.vue"),
      },
      {
        path: "/device_manage/device_manage",
        component: () => import("../views/deviceManage/device_manage.vue"),
      },
      {
        path: "/device_manage/charg_manage-type=undefined",
        component: () => import("../views/deviceManage/charg_manage.vue"),
      },
      {
        path: "/device_manage/charg_manage-type=2",
        component: () => import("../views/deviceManage/charg_manage.vue"),
      },
      {
        path: "/device_manage/charg_manage-type=3",
        component: () => import("../views/deviceManage/charg_manage.vue"),
      },
      {
        path: "/device_manage/charg_manage-type=4",
        component: () => import("../views/deviceManage/charg_manage.vue"),
      },
      {
        path: "/device_manage/newcharg_manage",
        component: () => import("../views/deviceManage/newcharg_manage.vue"),
      },
      {
        path: "/device_manage/bindcharg_manage",
        component: () => import("../views/deviceManage/bindcharg_manage.vue"),
      },
      {
        path: "/device_manage/operation_result",
        component: () => import("../views/deviceManage/operation_result.vue"),
      },
      {
        path: "/device_manage/device_add",
        component: () => import("../views/deviceManage/device_add.vue"),
      },
      {
        path: "/device_manage/add_code",
        component: () => import("../views/deviceManage/add_code.vue"),
      },
      {
        path: "/device_manage/card_search",
        component: () => import("../views/deviceManage/card_search.vue"),
      },
      {
        path: "/device_manage/card_manage",
        component: () => import("../views/deviceManage/card_manage.vue"),
      },
    ],
  },
  // 费用管理
  {
    path: "/fee_template",
    name: "feeTemplate",
    component: feeTemplate,

    redirect: "/fee_template/temp_manage2",
    children: [
      {
        path: "/fee_template/temp_manage2",
        component: () => import("../views/feeTemplate/temp_manage2.vue"),
      },
      {
        path: "/fee_template/temp_manage4",
        component: () => import("../views/feeTemplate/temp_manage4.vue"),
      },
      {
        path: "/fee_template/temp_add",
        component: () => import("../views/feeTemplate/temp_add.vue"),
      },

      {
        path: "/fee_template/formant_manage",
        component: () => import("../views/feeTemplate/formant_manage.vue"),
      },
      {
        path: "/fee_template/open_money2",
        component: () => import("../views/feeTemplate/open_money2.vue"),
      },
      {
        path: "/fee_template/open_money4",
        component: () => import("../views/feeTemplate/open_money4.vue"),
      },
      {
        path: "/fee_template/power_add",
        component: () => import("../views/feeTemplate/power_add.vue"),
      },

    ],
  },
  // 运维管理
  {
    path: "/fault_manage",
    name: "FaultManage",
    component: FaultManage,

    redirect: "/fault_manage/devops_count",
    children: [
      {
        path: "/fault_manage/fault_manage",
        component: () => import("../views/faultManage/fault_manage.vue"),
      },
      {
        path: "/fault_manage/fault_count",
        component: () => import("../views/faultManage/fault_count.vue"),
      },
      {
        path: "/fault_manage/devops_count",
        component: () => import("../views/faultManage/devops_count.vue"),
      },
      {
        path: '/fault_manage/station',
        component: () => import('../views/GIS/station.vue')
      },
      {
        path: '/fault_manage/state',
        component: () => import('../views/faultManage/state.vue')
      },
      {
        path: '/fault_manage/logquery',
        component: () => import('../views/faultManage/logquery.vue')
      },
    ],
  },
  // 运营管理
  {
    path: '/revenue_manage',
    name: 'RevenueManage',
    component: RevenueManage,

    redirect: '/revenue_manage/revenue_manage',
    children: [
      {
        path: "/revenue_manage/Value_added",
        component: () => import("../views/revenueManage/Value_added.vue"),
      },
      {
        path: '/revenue_manage/user_recharg',//
        name: 'user_recharg',
        component: () => import('../views/revenueManage/user_recharg.vue')
      },
      {
        path: '/revenue_manage/revenue_manage',//
        name: 'revenue_manage',
        component: () => import('../views/revenueManage/revenue_manage.vue')
      },
      {
        path: "/revenue_manage/ledger_manage",
        component: () => import("../views/revenueManage/ledger_manage.vue"),
      },
      {
        path: '/revenue_manage/order_refund',//
        name: 'order_refund',
        component: () => import('../views/revenueManage/order_refund.vue')
      },
      {
        path: '/revenue_manage/order_manage',//
        name: 'order_manage',
        component: () => import('../views/revenueManage/order_manage.vue')
      },
      {
        path: '/revenue_manage/refund_record',//
        name: 'refund_record',
        component: () => import('../views/revenueManage/refund_record.vue')
      },
      {
        path: "/revenue_manage/invest_detail",
        component: () => import("../views/revenueManage/invest_detail.vue"),
      },
      {
        path: "/revenue_manage/refund_detail",
        component: () => import("../views/revenueManage/refund_detail.vue"),
      },
      {
        path: "/revenue_manage/operation_manage",
        component: () => import("../views/operationManage/operation_manage.vue"),
      },
      {
        path: "/revenue_manage/revenue_ledger",
        component: () => import("../views/revenueLedger/revenue_ledger.vue"),
      },
      {
        path: "/revenue_manage/ledger_statistics",
        component: () => import("../views/revenueManage/ledger_statistics.vue"),
      }
    ]
  },
  // 报表管理
  {
    path: "/report_manage",
    name: "reportManage",
    component: reportManage,
    redirect: "/report_manage/operator_statistics",
    children: [
      // {
      //   path: "/report_manage/invest_collect",
      //   component: () => import("../views/reportManage/invest_collect.vue"),
      // },
      {
        path: "/report_manage/expend_collect",
        component: () => import("../views/reportManage/expend_collect.vue"),
      },
      {
        path: "/report_manage/expend_detail",
        component: () => import("../views/reportManage/expend_detail.vue"),
      },
      {
        path: "/report_manage/invest_collect",
        component: () => import("../views/reportManage/invest_collect.vue"),
      },
      {
        path: "/report_manage/station_statistics",
        component: () => import("../views/reportManage/station_statistics.vue"),
      },

      {
        path: "/report_manage/device_statistics",
        component: () => import("../views/reportManage/device_statistics.vue"),
      },
      {
        path: "/report_manage/user_statistics",
        component: () => import("../views/reportManage/user_statistics.vue"),
      },
      {
        path: "/report_manage/operator_statistics",
        component: () => import("../views/reportManage/operator_statistics.vue"),
      },

    ],
  },
  // GIS管理
  {
    path: '/gis',
    name: 'Gis',
    component: Gis,
    redirect: '/gis/station',
    children: [
      {
        path: '/gis/area',
        component: () => import('../views/GIS/area.vue')
      },
      {
        path: '/gis/gis',
        component: () => import('../views/GIS/gis.vue')
      },
      // {
      //   path:'/gis/grid',
      //   component:()=>import('../views/GIS/grid.vue')
      // },
      // {
      //   path:'/gis/station',
      //   component:()=>import('../views/GIS/station.vue')
      // },

    ]
  },
  // 消息管理

  // 系统管理
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    redirect: '/setting/user',
    children: [
      {
        path: '/setting/role',//角色管理
        name: 'Role',
        component: () => import('../views/Setting/role.vue')
      },
      {
        path: '/setting/user',//用户管理
        name: 'User',
        component: () => import('../views/Setting/user.vue')
      },
      {
        path: '/setting/access',//用户分配角色
        name: 'Access',
        component: () => import('../views/Setting/access.vue')
      },
      {
        path: '/setting/rolerouter',//角色分配权限
        name: 'RoleRouter',
        component: () => import('../views/Setting/rolerouter.vue')
      },
      {
        path: '/setting/router_access',//页面权限管理
        name: 'RouterAccess',
        component: () => import('../views/Setting/router_access.vue')
      },
    ]
  },
  {
    path: "/mobile_index", // 移动端页面
    name: "mobileIndex",
    component: mobileIndex,
    meta: {
      keepAlive: 0
    },
    redirect: "/mobile_index/mobile_home",

    children: [
      {
        path: "/mobile_index/mobile_home",
        component: () => import("../views/mobileDesign/mobile_home.vue"),
      },
      {
        path: "/mobile_index/user_manage",
        component: () => import("../views/mobileDesign/user_manage.vue"),
      },
      {
        path: "/mobile_index/order_manage",
        component: () => import("../views/mobileDesign/order_manage.vue"),
      },
      {
        path: "/mobile_index/order_history",
        component: () => import("../views/mobileDesign/order_history.vue"),
      },
      {
        path: "/mobile_index/device_manage",
        component: () => import("../views/mobileDesign/device_manage.vue"),
      },
      {
        path: "/mobile_index/report_manage",
        component: () => import("../views/mobileDesign/report_manage.vue"),
      },
      {
        path: "/mobile_index/charge_pile_manage",
        component: () => import("../views/mobileDesign/charge_pile_manage.vue"),
      },
      {
        path: "/mobile_index/revenue_manage",
        component: () => import("../views/mobileDesign/revenue_manage.vue"),
      },
      {
        path: "/mobile_index/order_refund",
        component: () => import("../views/mobileDesign/order_refund.vue"),
      },
      {
        path: "/mobile_index/user_recharg",
        component: () => import("../views/mobileDesign/user_recharg.vue"),
      },
      {
        path: "/mobile_index/charg_manage",
        component: () => import("../views/mobileDesign/charg_manage.vue"),
      },
      {
        path: "/mobile_index/user_search",
        component: () => import("../views/mobileDesign/user_search.vue"),
      },
      {
        path: "/mobile_index/user_statics",
        component: () => import("../views/mobileDesign/user_statics.vue"),
      },
      {
        path: "/mobile_index/fault_news",
        component: () => import("../views/mobileDesign/fault_news.vue"),
      },
      {
        path: "/mobile_index/ledger_manage",
        component: () => import("../views/mobileDesign/ledger_manage.vue"),
      },
      {
        path: "/mobile_index/refund_detail",
        component: () => import("../views/mobileDesign/refund_detail.vue"),
      },
      {
        path: "/mobile_index/invest_collect",
        component: () => import("../views/mobileDesign/invest_collect.vue"),
      },
    ],
  },





];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

<template>
  <div class="Mobileindex">
    <div class="ZHCDZheader">
      <el-row align="middle">
        <el-col :span="19" class="logo" style="margin-left: 15px">
          智慧设备管理系统
        </el-col>
        <el-col :span="3">
          <el-row>
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link" style="color: white;">
                  {{ userName == undefined ? "admin" : userName }}
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="userInfo()"
                      >个人信息</el-dropdown-item
                    >
                    <el-dropdown-item @click="logout()"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="buttomWarp"> -->
    <div class="menu" @click="drawer = true">
      <span class="glyphicon glyphicon-align-left"></span> Menu
    </div>
    <el-drawer class="drawer" v-model="drawer" direction="ltr" size='55%' :with-header="false">
      <div class="userInfo">
        <img src="@/assets/mjimg/avatar.png" />
        <p>{{ loginName }}</p>
        <p>{{ groups }}</p>
        <!-- <div class="logintime">
          上次登录：{{
            `${prevhour.year}/${prevhour.month}/${prevhour.date} ${prevhour.hour}:${prevhour.minute}`
          }}
        </div>
        <div class="logintime">
          当前登录：{{ `${year}/${month}/${date} ${hour}:${minute}` }}
        </div> -->
        <div class="userInfo_Line"></div>
      </div>
      <div class="navWarp">
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-home"></span> -->
          <router-link to="/mobile_index/mobile_home" @click="drawer = false">首页</router-link>
        </div>
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-user"></span> -->
          <router-link to="/mobile_index/user_manage" @click="drawer = false">用户管理</router-link>
        </div>
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-glass"></span> -->
          <router-link to="/mobile_index/device_manage" @click="drawer = false">设备管理</router-link>
        </div>
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-sort-by-order"></span> -->
          <router-link to="/mobile_index/order_manage" @click="drawer = false">订单管理</router-link>
        </div>
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-gbp"></span> -->
          <router-link to="/mobile_index/report_manage" @click="drawer = false">报表管理</router-link>
        </div>
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-globe"></span> -->
          <router-link to="/mobile_index/fault_news" @click="drawer = false">故障消息</router-link>
        </div>
        <div class="r_title">
          <!-- <span class="glyphicon glyphicon-globe"></span> -->
          <router-link to="/mobile_index/revenue_manage" @click="drawer = false">营收管理</router-link>
        </div>
      </div>
    </el-drawer>
    <div class="contentWarp">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import { search_pagepermission } from "@/request/api";
import { reactive, toRefs, ref } from "vue-demi";
import { onMounted , onBeforeUnmount} from "vue";
import { useRouter} from "vue-router";
export default {
  name: "mobileIndex",
  setup() {
    const router = useRouter();
    const data = reactive({
      modu: "mobile_index",
      drawer: false,
      // 当前时间
      year: "",
      month: "",
      date: "",
      hour: "",
      minute: "",
      week: "",
      // 一小时前的时间
      prevhour: {
        year: "",
        month: "",
        date: "",
        hour: "",
        minute: "",
        week: "",
      },
    });
    // 获取时间
    const getDate = () => {
      const now = new Date();
      data.year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      data.month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      data.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      data.hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      data.minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      const day = now.getDay();
      const weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      data.week = weeks[day];

      const prevHourtime = new Date(now.getTime() - 3600000);
      data.prevhour.year =
        prevHourtime.getFullYear() < 10
          ? "0" + prevHourtime.getFullYear()
          : prevHourtime.getFullYear(); //得到年份
      data.prevhour.month =
        prevHourtime.getMonth() + 1 < 10
          ? "0" + (prevHourtime.getMonth() + 1)
          : prevHourtime.getMonth() + 1; //得到月份
      data.prevhour.date =
        prevHourtime.getDate() < 10
          ? "0" + prevHourtime.getDate()
          : prevHourtime.getDate(); //得到日期
      data.prevhour.hour =
        prevHourtime.getHours() < 10
          ? "0" + prevHourtime.getHours()
          : prevHourtime.getHours(); //得到小时数
      data.prevhour.minute =
        prevHourtime.getMinutes() < 10
          ? "0" + prevHourtime.getMinutes()
          : prevHourtime.getMinutes(); //得到分钟数
    };
    // 定时器每分钟请求一次
    const timeInserVal = setInterval(() => {
      getDate();
      // getWeather();
    }, 60000);
    const loginName = ref(sessionStorage.getItem("name"));
    const logout = ()=>{
        sessionStorage.clear();
        router.push("/login");
    }
    onMounted(() => {
    });
    onBeforeUnmount(() => {
      clearInterval(timeInserVal);
    });
    return {
      ...toRefs(data),
      timeInserVal,
      loginName,
      logout
    };
  },
};
</script>
<style scoped>
.ZHCDZheader {
  /* background: rgb(1,50,90); */
  background: rgb(66, 167, 169);
  background-size: 100% 100%;
}
.ZHCDZheader .logo {
  font-size: 0.6rem;
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.ZHCDZheader > .el-row {
  height: 1.5rem;
}
.menu {
  line-height: 1rem;
  border-bottom: 0.001rem solid lightgray;
  padding: 0 0.4rem;
  font-size: 0.4rem;
}
::v-deep .el-drawer__body{
    background-color: rgb(66, 167, 169);
    /* padding: 0; */
}
.userInfo {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  justify-content: center;
}
.userInfo > img {
  width: 3rem;
  height: 3rem;
  display: block;
  border-radius: 3rem;
  margin: 0 auto;
}
.userInfo p {
  font-size: 0.4rem;
  /* color: white; */
  color: white;
  text-align: center;
  margin-top: 0.5rem;
}
.userInfo .userInfo_Line {
  height: 0.01rem;
  border-bottom: 2px dashed white;
  width: 4rem;
  margin: 0.5rem auto 0 auto;
}
.r_title {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: white;
  line-height: 1rem;
  text-align: center;
  /* font-weight: 600; */
  font-size: 0.4rem;
}
.r_title:hover {
  background: radial-gradient(rgba(203,255,255, .6),rgba(154, 219, 219 , .6)) !important;
  border-radius: 0.2rem;
}
.r_title a {
  font-size: 0.4rem;
  position: relative;
  top: 0px;
  left: 0.5rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
/* .r_title a:hover {
  color: white;
} */
.contentWarp {
  width: 100%;
  height: auto;
  padding: 0.5rem;
  box-sizing: border-box;
  overflow: auto;
  z-index: 999;
  /* background-color: #E3F9F9; */
}

</style>

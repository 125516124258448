<template>
    <div class="gis">
      <ZHCDZhead></ZHCDZhead>
      <div class="buttomWarp">
        <div class="navWarp">
          <LeftNav :menuList="menu" :modu="modu" v-if="flag"></LeftNav>
        </div>
        <div class="contentWarp">
          <router-view></router-view>
        </div>
      </div>
      <div class="todoList">
        <TODO></TODO>
      </div>
    </div>
  </template>
  
  <script>
  import LeftNav from "@/component/nav";
  import ZHCDZhead from "@/component/header.vue";
  import { search_pagepermission } from "@/request/api";
  // import { navList } from "../../router/navList";
  import { onMounted, reactive, toRefs } from "vue-demi";
  export default {
    name: "ReportManage",
    components: {
      LeftNav,
      ZHCDZhead,
    },
    setup() {
      // 创建左侧导航
      const data = reactive({
        modu: "report_manage",
        menu: [],
        flag: false,
      });
      // 获取页面路由列表
      const getRouterList = () => {
        const dataa = {
          keyword: "",
          current_page: 1,
          page_size: 100,
        };
        search_pagepermission(dataa).then((res) => {
          if (res) {
            const Basic = res.data;
            arr2Json(Basic);
          }
        });
      };
   // 转换为tree所需要的格式
   const arr2Json = (Basic) => {
      const pageList = [];
      const setp1 = new Promise((res) => {
        const allList = Basic;
        const pageIDs = sessionStorage.getItem("perm_list").split(",");
        pageIDs.forEach((ids) => {
        allList.forEach((all) => {
          if (all.id == ids && all.grand_parent_path == "report_manage") {
            pageList.push(all);
          }
        });
        });
        res(pageList);
      });
      const setp2 = new Promise((resf) => {
        setp1.then((res) => {
          const list = res;
          const a = list.map((a) => a.perm_list);
          const parent = Array.from(new Set(a));
          parent.forEach((item, index) => {
            parent[index] = {
              title: item,
              items: [],
              show: true,
            };
          });
          resf(parent);
        });
      });
      setp2.then((res) => {
        const list = res;
        list.forEach((l) => {
          pageList.forEach((p) => {
            if (l.title == p.perm_list) {
              l.items.push({
                text: p.perm_info,
                path: p.path,
                show: true,
              });
            }
          });
        });
        data.menu = list;
        data.flag = true;
      });
    };

      onMounted(() => {
        // arr2Json(navList);
        getRouterList()
      });
      return {
        ...toRefs(data),
      };
    },
    data() {
      return {};
    },
  };
  </script>
  
  <style scoped>
  .buttomWarp {
    display: flex;
  }
  .contentWarp {
    /* width: calc(100vw - 310px); */
    width: 100%;
  margin-right: 40px;
    margin-top: 60px;
    height: calc(100vh - 170px);
    overflow-x: hidden;
    margin-left: 40px;
  }
  .todoList {
    position: fixed;
    right: 0;
    z-index: 999;
    top: 150px;
  }
  </style>
  
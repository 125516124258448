import ajax from "./request";
// import axios from 'axios'
export const baseUrl = 'https://www.xwzncd.cn:28100';
// export const baseUrl = 'http://192.168.0.128:8080';
// export const baseUrl = 'http://192.168.0.2:8080';

//  登录
export const Login = (data) => ajax(`${baseUrl}/superuser/login`, data, 'post');
// export const logout = (data) => ajax(`${baseUrl}/user_manage/logout/`, data);
// 登录验证码
export const captcha = (data) => ajax(`${baseUrl}/user/create_images`, data, 'get');
// 修改密码
export const super_pwd = (data) => ajax(`${baseUrl}/superuser/change_pwd`, data, 'post');

// 用户修改密码
export const update_pwd = (data) => ajax(`${baseUrl}/user_manage/update_pwd/`, data, 'post');

// 首页数据汇总
export const home_data_summary = (data) => ajax(`${baseUrl}/report/home_data_summary`, data, 'post');

// 用户数据汇总
export const user_statistics = (data) => ajax(`${baseUrl}/report/user_statistics`, data, 'post');

// 生成故障单
export const pcmalfunction = (data) => ajax(`${baseUrl}/message_center/pcmalfunction`, data, 'post');
// 全部故障信息
export const fault_message = (data) => ajax(`${baseUrl}/message_center/fault_message`, data, 'post');
// 故障信息处理保存
export const processing_failure1 = (data) => ajax(`${baseUrl}/message_center/processing_failure`, data, 'put');
// 故障处理完成
export const processing_failure2 = (data) => ajax(`${baseUrl}/message_center/processing_failure`, data, 'post');
// 获取天气
// export const get_weather = () => ajax(`${baseUrl}/message_center/get_weather`, 'get');
export const get_weather = (data) => ajax(`${baseUrl}/message_center/get_weather`, data, 'get');


// ------------------------------------------- 用户管理 -------------------------------------------
// 查询用户
export const sel_user = (data) => ajax(`${baseUrl}/user/sel_user`, data, 'post');
// 添加用户
export const add_user = (data) => ajax(`${baseUrl}/user/add_user`, data, 'post');


// 用户登录
// export const login = (data) => ajax(`${baseUrl}/user/login`, data,'post');
// 用户注销
export const logout = (data) => ajax(`${baseUrl}/user/logout`, data, 'post');
// 添加用户密码
export const change_pwd = (data) => ajax(`${baseUrl}/user/change_pwd`, data, 'post');
// 编辑用户
export const upd_user = (data) => ajax(`${baseUrl}/user/upd_user`, data, 'post');
// 在线用户
export const online_order = (data) => ajax(`${baseUrl}/order/sel_realtime_order`, data, 'post');


// ------------------------------------------- 订单管理 -------------------------------------------
// 实时订单查询
// export const sel_order = (data) => axios.post('/pcapi/order/sel_realtime_order', data);
export const sel_order = (data) => ajax(`${baseUrl}/order/sel_realtime_order`, data, 'post');

// 历史订单查询
export const sel_history_order = (data) => ajax(`${baseUrl}/order/sel_history_order`, data, 'post');
// 异常订单查询
export const anomaly_order = (data) => ajax(`${baseUrl}/order/anomalyorder`, data, 'post');
// 开启充电
export const start_chage_yun = (data) => ajax(`${baseUrl}/device_yun/start_charge_yun`, data, 'post');
// 关闭充电
// export const stop_charge = (data) => ajax(`${baseUrl}/device/stop_charge`, data,'post');

// ------------------------------------------- 设备管理 -------------------------------------------

// 添加充电站
export const add_station = (data) => ajax(`${baseUrl}/device/add_station`, data, 'post');
// 查询充电站
export const sel_station = (data) => ajax(`${baseUrl}/device/sel_station`, data, 'post');
// 编辑充电站
export const upd_station = (data) => ajax(`${baseUrl}/device/upd_station`, data, 'post');
// 删除充电站
export const remove_station = (data) => ajax(`${baseUrl}/device/remove_station`, data, 'post');
// 设备绑定
export const manage_device = (data) => ajax(`${baseUrl}/device/manage_device`, data, 'post');
// 添加设备
export const add_device = (data) => ajax(`${baseUrl}/device/add_device`, data, 'post');
// 设备查询
export const sel_station_all_info = (data) => ajax(`${baseUrl}/device/sel_station_all_info`, data, 'post');
// 设备查询
export const sel_device = (data) => ajax(`${baseUrl}/device/sel_device`, data, 'post');
// 未分配充电站设备
export const no_binding = (data) => ajax(`${baseUrl}/device/no_binding`, data, 'post');
// 设备分配运营商
export const allot_device = (data) => ajax(`${baseUrl}/device/allot_device`, data, 'post');

// 设备编辑
export const upd_device = (data) => ajax(`${baseUrl}/device/upd_device`, data, 'post');
// 设备删除
export const remove_device = (data) => ajax(`${baseUrl}/device/remove_device`, data, 'post');
// 开启充电
export const start_charge = (data) => ajax(`${baseUrl}/device/start_charge`, data, 'post');
// 关闭充电
export const stop_charge = (data) => ajax(`${baseUrl}/device/stop_charge`, data, 'post');

// 开启关闭退费
export const return_switch = (data) => ajax(`${baseUrl}/device/return_switch `, data, 'post');
// 下发计费模板
export const billing_model = (data) => ajax(`${baseUrl}/device/billing_model`, data, 'post');
// 查询运营商
export const operator_data = (data) => ajax(`${baseUrl}/device/operator_data`, data, 'post');
// 下发二维码
export const qr_code = (data) => ajax(`${baseUrl}/device/qr_code`, data, 'post');

// 解绑
export const unbind_device1 = (data) => ajax(`${baseUrl}/device/unbind_device`, data, 'get');
// 初始化
export const unbind_device2 = (data) => ajax(`${baseUrl}/device/unbind_device`, data, 'post');
// 设备停止充电
export const simple_stop_charge = (data) => ajax(`${baseUrl}/device_yun/simple_stop_charge`, data, 'post');
// 设备临停
export const temporary_closure = (data) => ajax(`${baseUrl}/device/temporary_closure`, data, 'post');
// 参数查询
export const device_setup = (data) => ajax(`${baseUrl}/device/device_setup`, data, 'post');
// 配置参数修改
export const device_setup_up = (data) => ajax(`${baseUrl}/device/device_setup_up`, data, 'post');
// 重启
export const restartdevice = (data) => ajax(`${baseUrl}/device/restartdevice`, data, 'post');

// 设备添加端口数量
export const station_device_port = (data) => ajax(`${baseUrl}/device/station_device_port`, data, 'get');

// 卡信息查询
export const sel_card = (data) => ajax(`${baseUrl}/iot/sel_card`, data, 'post');
// 查询套餐
export const sel_meal = (data) => ajax(`${baseUrl}/iot/set_meal`, data, 'post');
// 激活物联网卡
export const active_card = (data) => ajax(`${baseUrl}/iot/active_card`, data, 'post');
// 暂停物联网卡
export const stop_card = (data) => ajax(`${baseUrl}/iot/stop_card`, data, 'post');
// 续费二维码
export const card_pay = (data) => ajax(`${baseUrl}/iot/card_pay`, data, 'post');
// // 物联网卡管理
// export const set_meal = (data) => ajax(`${baseUrl}/iot/set_meal`, data, 'post');
// // 修改价格
export const upd_meal = (data) => ajax(`${baseUrl}/iot/set_meal`, data, 'patch');


// ---------------------------------------------计费模板-------------------------------------------
// 模板查询
export const sel_template = (data) => ajax(`${baseUrl}/template/sel_template`, data, 'post');
// 模板编辑
export const upd_template = (data) => ajax(`${baseUrl}/template/upd_template`, data, 'post');
// 模板删除
export const delete_template = (data) => ajax(`${baseUrl}/template/delete_template`, data, 'post');
// 电动车模板添加 
export const add_template = (data) => ajax(`${baseUrl}/template/add_template`, data, 'post');
// 电动汽车模板添加 
export const billing_models = (data) => ajax(`${baseUrl}/device/billing_models`, data, 'post');
// 充电计费模板添加
export const set_amount = (data) => ajax(`${baseUrl}/template/set_amount`, data, 'post');
// 充电计费模板获取
export const amend_money = (data) => ajax(`${baseUrl}/template/amend_money`, data, 'post');
//  // 模板查询无分页
export const sel_temp = (data) => ajax(`${baseUrl}/template/sel_temp`, data, 'post');


// ---------------------------------------------报表管理-------------------------------------------
// 充电站使用统计
export const charge_station_statistics = (data) => ajax(`${baseUrl}/report/charge_station_statistics`, data, 'post');
// 设备使用统计
export const device_statistics = (data) => ajax(`${baseUrl}/report/device_statistics`, data, 'post');
// 用户消费汇总
export const user_consumption_statistics = (data) => ajax(`${baseUrl}/report/user_consumption_statistics`, data, 'post');
// 用户消费明细
export const user_consumption_detail = (data) => ajax(`${baseUrl}/report/user_consumption_detail`, data, 'post');
// 用户充值汇总
export const user_charge_statistics = (data) => ajax(`${baseUrl}/report/user_charge_statistics`, data, 'post');
// 用户充值明细
export const user_charge_detail = (data) => ajax(`${baseUrl}/report/user_charge_detail`, data, 'post');
// 用户退费明细
// export const user_refund_detail = (data) => ajax(`${baseUrl}/report/user_refund_detail`, data,'post');
// 运营商统计 
export const operator_revenue = (data) => ajax(`${baseUrl}/report/operatorrevenue`, data, 'post');


// ---------------------------------------------GIS管理-------------------------------------------
// 充电站经纬度
export const gis_station = (data) => ajax(`${baseUrl}/gis_station`, data, 'get');
// gis站点 
export const gisinfo = (data) => ajax(`${baseUrl}/report/gisinfo`, data, 'post');



// // ------------------------------------------- 系统设置 -------------------------------------------
// // 系统设置——角色管理 列表
export const role = (data) => ajax(`${baseUrl}/user/roles`, data, 'get');
// 系统设置——角色添加
export const add_role = (data) => ajax(`${baseUrl}/user/roles`, data, 'post');
// 系统设置——角色编辑
export const edit_role = (data) => ajax(`${baseUrl}/user/roles`, data, 'put');
// 系统设置——角色删除
export const delete_role = (data) => ajax(`${baseUrl}/user/roles`, data, 'delete');
// 系统设置——权限列表查询
export const search_pagepermission = (data) => ajax(`${baseUrl}/user/perm`, data, 'get');
// 系统设置——权限列表添加
export const add_pagepermission = (data) => ajax(`${baseUrl}/user/perm`, data, 'post');
// 系统设置——权限列表编辑
export const edit_pagepermission = (data) => ajax(`${baseUrl}/user/perm`, data, 'put');
// 系统设置——权限删除
export const delete_pagepermission = (data) => ajax(`${baseUrl}/user/perm`, data, 'delete');
// // 系统设置——用户管理 获取列表
export const sel_superuser = (data) => ajax(`${baseUrl}/superuser/sel_superuser`, data, 'post');
// // 系统设置——用户管理 添加
// export const add_superuser = (data) => ajax(`${baseUrl}/superuser/add_superuser`, data, 'post');
export const add_superuser1 = (data) => ajax(`${baseUrl}/user/add_user`, data, 'post');
// // 系统设置——用户管理 认证
export const commercial_tenant = (data) => ajax(`${baseUrl}/wechat/commercial_tenant`, data, 'post');
// // 系统设置——用户管理 编辑
export const edit_superuser = (data) => ajax(`${baseUrl}/superuser/upd_superuser`, data, 'post');
// // 系统设置——用户管理 禁用
export const prohibit_superuser = (data) => ajax(`${baseUrl}/superuser/prohibit_superuser`, data, 'delete');
// 禁用用户
export const prohibit_user = (data) => ajax(`${baseUrl}/user/prohibit_user`, data, 'post');
// 启用用户
export const active_user = (data) => ajax(`${baseUrl}/user/active_user`, data, 'post');

// // 系统设置——用户管理 删除
export const delUser = (data) => ajax(`${baseUrl}/superuser/delete_superuser`, data, 'post');
// // 系统设置——用户角色管理 查询
export const user_roles_manage_g = (data) => ajax(`${baseUrl}/superuser/superuser_roles_manage`, data, 'get');
// // 系统设置——用户角色管理 选择角色
export const roles = (data) => ajax(`${baseUrl}/user/roles`, data, 'get');
// 系统设置——用户角色管理 添加角色
export const user_roles_manage_p = (data) => ajax(`${baseUrl}/user/user_roles_manage`, data, 'post');
// 系统设置——用户角色管理 删除角色
export const delUser1 = (data) => ajax(`${baseUrl}/superuser/superuser_roles_manage`, data, 'delete');
// 系统设置——角色权限管理 查询
export const roles_perm_g = (data) => ajax(`${baseUrl}/user/roles_perm_manage`, data, 'get');
// 系统设置——角色权限管理 添加权限
export const roles_perm_p = (data) => ajax(`${baseUrl}/user/roles_perm_manage`, data, 'post');
// 系统设置——角色权限管理 删除
export const roles_perm_del = (data) => ajax(`${baseUrl}/user/roles_perm_manage`, data, 'delete');


// ---------------------------------------------营收管理-------------------------------------------
// 数据汇总
export const revenue_management = (data) => ajax(`${baseUrl}/report/revenue_management`, data, 'post');
// 用户充值
export const charge_money = (data) => ajax(`${baseUrl}/user/charge_money`, data, 'post');
// 余额退费
export const re_money = (data) => ajax(`${baseUrl}/wechat/re_money`, data, 'get');
// 订单退费
export const order_refund = (data) => ajax(`${baseUrl}/order/reorder`, data, 'post');
export const re_money1 = (data) => ajax(`${baseUrl}/wechat/re_money`, data, 'post');
// 订单退费明细
export const order_refund_detail = (data) => ajax(`${baseUrl}/report/order_refund_detail `, data, 'post');
// 余额退费明细
export const user_refund_detail = (data) => ajax(`${baseUrl}/report/user_refund_detail `, data, 'post');

// // 分账管理
// export const independent_account= (data) => ajax(`${baseUrl}/report/independent_account`, data,'post');
// 分账明细
export const independent_account_system = (data) => ajax(`${baseUrl}/report/independent_account_system`, data, 'post');
// 分账统计 
export const report_accountcount = (data) => ajax(`${baseUrl}/report/accountcount`, data, 'post');

// 获取位置定位
export const get_city = (data) => ajax(`${baseUrl}/message_center/get_city`, data, 'post');
// 用户钱包余额 
export const selwallet = (data) => ajax(`${baseUrl}/report/selwallet`, data, 'post');
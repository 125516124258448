<template>
  <div class="ZHCDZheader">
    <el-row align="middle">
      <el-col :span="5" :offset="1" class="logo" style="margin-left: 30px">
        <!-- <img src="../assets/img/logo002.png" alt="" /> -->
        <router-link to="/sys_index/home" style="color: white">
          <!-- <p>22</p> -->行充充智慧充电运营管理系统
        </router-link>
      </el-col>
      <el-col :span="11" :offset="1" class="headerNav">
        <!-- <div class="icon" @click="scrollLeft">
          <el-icon><CaretLeft /></el-icon>
        </div> -->
        <div class="out" id="out">
          <div class="in" id="in" :style="{ left: left + 'px' }">
            <template v-for="item in navList" :key="item.path">
              <router-link :to="'/' + item.path" class="item">
                <div
                  :class="
                    headActive == `${item.path}` ? 'cur' : 'headerNavItem'
                  "
                >
                  <p>{{ item.label }}</p>
                </div>
              </router-link>
            </template>
            <!-- <router-link to="/user_manage">
                  <div :class="headActive == 'user_manage' ? 'cur' : 'headerNavItem'">
                    <p>用户管理</p>
                  </div>
                </router-link>
                <router-link to="/order_manage">
                  <div :class="headActive == 'order_manage' ? 'cur' : 'headerNavItem'">
                    <p> 订单管理</p>
                  </div>
                </router-link>
                <router-link to="/device_manage">
                  <div
                    :class="headActive == 'device_manage' ? 'cur' : 'headerNavItem'"
                  >
                    <p>设备管理</p>
                  </div>
                </router-link>
         
                <router-link to="/fee_tempalte">
                  <div
                    :class="headActive == 'fee_tempalte' ? 'cur' : 'headerNavItem'"
                  >
                    <p>计费模板</p>
                  </div>
                </router-link>

                <router-link to="/report_manage">
                  <div :class=" headActive == 'report_manage' ? 'cur' : 'headerNavItem' ">
                    <p>报表管理</p>
                  </div>
                </router-link>
                <router-link to="/setting">
                  <div
                    :class="headActive == 'setting' ? 'cur' : 'headerNavItem'"
                  >
                    <p>系统管理</p>
                  </div>
                </router-link> -->
          </div>
        </div>
        <!-- <div class="icon" @click="scrollRight">
          <el-icon><CaretRight /></el-icon>
        </div> -->
      </el-col>
      <el-col :span="7">
        <el-row style="height: 8px; line-height: 8px; margin-left: 10px">
          <el-col :span="5">
            <div class="msg">
              <router-link style="color: white" to="/fault_manage/fault_manage">
                <p>我的消息</p>
              </router-link>
            </div>
          </el-col>
          <el-col :span="5">
            <el-dropdown trigger="click">
              <p class="el-dropdown-link" style="height: 6px; line-height: 6px">
                {{ userName == undefined ? "admin" : userName }}
              </p>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item @click="userInfo()"
                    >个人信息</el-dropdown-item
                  > -->
                  <el-dropdown-item @click="change()"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item @click="logout()"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
          <el-col
            :span="12"
            style="justify-content: space-between; display: flex"
          >
            <div class="el-dropdown-link">
              {{ `${year}-${month}-${date} ${hour}:${minute}` }}
            </div>
            <div class="el-dropdown-link">
              {{ weather.city }} {{ weather.text }} {{ weather.windDir }}
              {{ weather.temp }}℃
            </div>
          </el-col>
          <!-- <el-col :span="6">
            
          </el-col> -->
        </el-row>
      </el-col>
    </el-row>
    <el-dialog v-model="dialogEditPassword" title="修改密码">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="110px"
      >
        <el-form-item label="原密码" prop="oldPassWord">
          <el-input
            clearable
            v-model="ruleForm.oldPassWord"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassWord">
          <el-input
            clearable
            v-model="ruleForm.newPassWord"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassWord">
          <el-input
            clearable
            v-model="ruleForm.confirmPassWord"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditPassword = false">取消</el-button>
          <el-button
            type="primary"
            class="buttonPri"
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.ZHCDZheader {
  background: #009193;
  /* border-bottom: solid white 1px; */
  background-size: 100% 100%;
}
.ZHCDZheader .logo {
  font-size: 23px;
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.ZHCDZheader .logo img {
  width: 40px;
  vertical-align: middle;
}
.ZHCDZheader > .el-row {
  height: 60px;
}
.headerNav {
  display: flex;
  margin: 0 15px 0 2px;
  height: 60px;
}
.headerNav .in {
  height: 60px;
}
.headerNav p {
  font-size: 16px;
  color: white;
  text-align: center;
  font-weight: 800;
  line-height: 60px;
  /* width: 150px;;*/
  padding: 0 15px;
}
.headerNav p > img {
  width: 18px;
  /* height: 2px; */
  vertical-align: middle;
  margin-bottom: 5px;
}

.out {
  width: 100%;
  overflow: hidden;
  /* border: 1px solid white; */
  position: relative;
}
.in {
  width: 2005px;
  /* border: 1px solid red; */
  display: flex;
  position: absolute;
  transition: all 1s;
}
.icon {
  color: white;
  /* vertical-align: middle; */
  /* line-height: 35px; */
  width: 22px;
  height: 22px;
  /* opacity: 0.7; */
  text-align: center;
  line-height: 25px;
  margin-top: 9px;
  font-size: 25px;
}

.item {
  flex-shrink: 0;
  margin: 0 5px;
  text-decoration: none;
}
.item p {
  padding: 0 15px;
}
.msg {
  vertical-align: middle;
  color: white;
  border-right: 1px solid white;
  margin-left: 30px;
  font-size: 13px;
  /* margin-top: 2px; */
}
/* .msg img {
  width: 12px;
  margin-right: 5px;
  vertical-align: middle;
} */
.cur {
  cursor: pointer;
  font-weight: 600;
  /* background-image: radial-gradient(circle,rgb(188, 252, 252),rgb(138, 224, 224)) !important; */
  background-color: rgb(46, 112, 123) !important;
}
.el-dropdown-link {
  text-align: center;
  color: white;
  margin-left: 15px;
  cursor: pointer;
  vertical-align: middle;
}
.el-dropdown-link img {
  width: 7px;
  vertical-align: middle;
}
.mhcx {
  position: relative;
}
.mhcx img {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 15px;
}
</style>

<script>
import { useRouter } from "vue-router";
import { ref, onMounted, onBeforeUnmount, reactive, toRefs } from "vue";
import { super_pwd, fault_message } from "../request/api";
import { ElMessage, ElMessageBox } from "element-plus";

// import JSEncrypt from "jsencrypt";
export default {
  name: "ZHCDZheader",
  data() {
    //   新密码校验
    const samePassword1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.confirmPassWord !== "") {
          this.$refs.ruleForm.validateField("confirmPassWord");
        }
        callback();
      }
    };
    // 确认密码校验
    const samePassword2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请在再次输入密码"));
      } else if (value !== this.ruleForm.newPassWord) {
        callback(new Error("两次密码的结果不一致"));
      } else {
        callback();
      }
    };
    // 旧密码校验
    const noEmpty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    return {
      userName: sessionStorage.getItem("name"),
      dialogEditPassword: false,
      ruleForm: {
        oldPassWord: "",
        newPassWord: "",
        confirmPassWord: "",
      },
      // 校验规则
      rules: {
        oldPassWord: [{ validator: noEmpty, trigger: "blur" }],
        newPassWord: [{ validator: samePassword1, trigger: "blur" }],
        confirmPassWord: [{ validator: samePassword2, trigger: "blur" }],
      },
    };
  },
  setup() {
    const headActive = ref("home");
    const data = reactive({
      province: "",
      myaddress: "",
      // 当前时间
      year: "",
      month: "",
      date: "",
      hour: "",
      minute: "",
      week: "",
      allList: [],
      navList: [],
      weather: {},
    });
    const getDate = () => {
      const now = new Date();
      data.year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      data.month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      data.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      data.hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      data.minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
    };

    // 定时器每分钟请求一次
    const timeInserVal = setInterval(() => {
      getDate();
    }, 60000);
    onBeforeUnmount(() => {
      clearInterval(timeInserVal);
    });
    const getNews = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
      };
      fault_message(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            if (res.data.length > 0) {
              console.log("有消息000");
            }
            // data.total = res.data.length
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    
    onMounted(() => {
      // fetchPowerData();
      getDate();
      // getNews()
      // getWeather()
      data.weather = {
        city:sessionStorage.getItem("city"),
        text:sessionStorage.getItem("text"),
        windDir:sessionStorage.getItem("windDir"),
        temp:sessionStorage.getItem("temp"),
      }
      // 实现选中状态不会因为页面刷新变更
      const actiavIndex = useRouter().currentRoute.value.path;
      console.log("ac", actiavIndex);
      const site = actiavIndex.split("/");
      headActive.value = site[site.length - 2];
      console.log("head", headActive);
      // getLoad()
      data.navList = JSON.parse(sessionStorage.getItem("uniqueArr"));
    });
    // 初始化rootSize
    return {
      headActive,
      ...toRefs(data),
      getDate,
      // getLoad,
      timeInserVal,
    };
  },

  methods: {
    userInfo() {
      // console.log(123);
      this.$router.push("/user_info");
    },
    change() {
      this.dialogEditPassword = true;
    },
    logout() {
      ElMessageBox.confirm("确定要退出系统吗？", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then(() => {
        sessionStorage.clear();
        this.$router.push("/login");
      });
    },
    // 修改密码表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("11111");
          // const oldpwd = this.rasEncryption(this.ruleForm.oldPassWord);
          // const newpwd = this.rasEncryption(this.ruleForm.newPassWord);
          const dataa = {
            username: sessionStorage.getItem("name"),
            old_password: this.ruleForm.oldPassWord,
            new_password: this.ruleForm.newPassWord,
            // affirm_pwd: this.ruleForm.confirmPassWord,
          };
          super_pwd(dataa).then((res) => {
            console.log(res);
            // if (res.code == 0) {
            ElMessageBox.alert("密码修改成功,需要重新登录", "消息", {
              confirmButtonText: "确认",
              type: "success",
            })
              .then(() => {
                sessionStorage.clear();
                this.$router.push({ path: "/login" });
              })
              .catch(() => {
                sessionStorage.clear();
                this.$router.push({ path: "/login" });
              });
            // }
          });
        } else {
          // 校验未通过
          return false;
        }
      });
    },

    //  scrollRight() {
    //     const boxLength = document.getElementById("out").clientWidth;
    //     const boxLength1 = document.getElementById("in").clientWidth;
    //     if (this.left > boxLength - boxLength1) {
    //       this.left -= 410;
    //     }
    //     console.log(this.left);
    //   },
    //   scrollLeft(){
    //     if (this.left < 0) {
    //           this.left += 410;
    //     }
    //   }
  },
  watch: {
    left: function (newLeft) {
      sessionStorage.setItem("left", newLeft);
    },
  },
};
</script>

<template>
  <div class="Sysindex">
    <ZHHWheader></ZHHWheader>
    <div class="buttomWarp">
      <div class="navWarp">
        <LeftNav
          :menuList="menu"
          :modu="modu"
          v-if="flag"
          :homeShowInfo="homeShowInfo"
        ></LeftNav>
      </div>
      <div class="contentWarp">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "@/component/nav";
import { onMounted} from "vue";
import ZHHWheader from "@/component/header.vue";
// import { search_pagepermission } from "@/request/api";
import { reactive, toRefs } from "vue-demi";
import myBMap from "../../component/vueBMap";
import axios from "axios";
export default {
  name: "Sysindex",
  components: {
    LeftNav,
    ZHHWheader,
  },
  setup() {
    // 创建左侧导航
    const data = reactive({
      modu: "sys_index",
      menu: [],
      flag: true,
      homeShowInfo: true,
    });
    // 结合浏览器获取城市位置(我只需要获取省和市,具体看个人需求)
    const getCity = () => {
      // const BMap = (window as any).BMapGL;
      myBMap.init().then((BMap) => {
        let myCity = new BMap.LocalCity();
        myCity.get(
          (result) => {
            let geoc = new BMap.Geocoder();
            geoc.getLocation(result.center, (res) => {
              sessionStorage.setItem("city",res.addressComponents.city);
              getLocationId(
                res.addressComponents.province,
                res.addressComponents.city
              );
            });
          },
          { enableHighAccuracy: true }
        );
      });
    };

    // 获取城市id
    const getLocationId = (province, city) => {
      axios({
        method: "get",
        url:
          "https://geoapi.qweather.com/v2/city/lookup?key=56a14978f76747a8897384f7bef56c20&adm=" +
          province +
          "&location=" +
          city,
      }).then((res) => {
        getWeather1(res.location[0].id);
      });
    };

    // 获取天气
    const getWeather1 = (id) => {
      axios({
        method: "get",
        url:
          "https://devapi.qweather.com/v7/weather/now?key=56a14978f76747a8897384f7bef56c20&location=" +
          id,
      }).then((res) => {
        sessionStorage.setItem("text",res.now.text);
        sessionStorage.setItem("windDir",res.now.windDir);
        sessionStorage.setItem("temp",res.now.temp);

      });
    };
    onMounted(() => {
      getCity();
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style scoped>
.buttomWarp {
  display: flex;
}
.contentWarp {
  /* width: calc(100vw - 240px); */
  width: 100%;
  margin-right: 40px;
  margin-top: 60px;
  height: calc(100vh - 170px);
  overflow: auto;
  margin-left: 40px;
}
</style>
